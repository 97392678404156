<template>
  <div>
    <div class='nob p-24 pt-48'>
      <div class='wid m-auto'>
        <div class='flex items-center'>
          <a class='flex-shrink-0'
            href=''>
            <img class='ink'
              src='images/b93e.png'>
          </a>
          <div class='flex ml-24 w-full text-center shadow-sm cursor-pointer'>
            <div class='som p-16 w-full font-bold rounded-l transition duration-200'
              :class='{ "active": tab === "login" || tab === "password" }'
              @click='tab = "login"'>
              {{ $t('common.log-in') }}
            </div>
            <div class='som p-16 w-full font-bold rounded-r transition duration-200'
              :class='{ "active": tab === "register" }'
              @click='tab = "register"'>
              {{ $t('common.sign-up') }}
            </div>
          </div>
        </div>
        <login-section v-if='tab === "login"'/>
        <register-section v-if='tab === "register"'/>
        <password-section v-if='tab === "password"'/>
        <providers-section/>
      </div>
    </div>
    <bottom-navigation-component/>
  </div>
</template>

<script>
  import BottomNavigation from '@/components/bottom-navigation'
  import store from '@/store'
  import Login from './login'
  import Password from './password'
  import Providers from './providers'
  import Register from './register'

  const linkWithPendingCredential = async function () {
    let json = sessionStorage.getItem('pending-credential')
    if (json) {
      sessionStorage.removeItem('pending-credential')
      await store.dispatch('auth/linkWithCredential', { json })
    }
  }

  export default {
    components: {
      'bottom-navigation-component': BottomNavigation,
      'login-section': Login,
      'password-section': Password,
      'providers-section': Providers,
      'register-section': Register
    },

    data: () => ({
      tab: 'login'
    }),

    computed: {
      user () {
        return this.$store.state.auth.user
      }
    },

    methods: {
      redirect () {
        linkWithPendingCredential()
        this.$router.push(this.$router.from || { name: 'settings' })
      }
    },

    async beforeRouteEnter (to, from, next) {
      let user = await store.dispatch('auth/getUser')
      if (user) {
        linkWithPendingCredential()
        next({ name: 'settings' })
        return
      }
      next()
    }
  }
</script>

<style lang='scss' scoped>
  .wid {
    max-width: 360px;
  }

  .ink {
    height: 60px;
  }

  .som {
    @apply
      text-gray-500
      bg-gray-300;

    &:hover {
      @apply text-gray-700;
    }

    &.active {
      @apply
        text-gray-900
        bg-white;
    }
  }

  @screen desktop {
    .nob {
      @apply mt-48;
    }
  }
</style>
