<template>
  <div>
    <div class='mt-48'>
      <div class='text-center text-gray-500'>{{ $t('pages.login.72fd251c') }}</div>
      <button-element class='is-block p-16 mt-24 text-gray-700 bg-white shadow-sm'
        @click.native='signInWithRedirect("facebook.com")'>
        <facebook-svg class='h-24'/>
        <div class='ml-16'>{{ $t('common.facebook') }}</div>
      </button-element>
      <button-element class='is-block p-16 mt-16 text-gray-700 bg-white shadow-sm'
        @click.native='signInWithRedirect("google.com")'>
        <google-svg class='h-24'/>
        <div class='ml-16'>{{ $t('common.google') }}</div>
      </button-element>
      <button-element class='is-block p-16 mt-16 text-gray-700 bg-white shadow-sm'
        @click.native='signInWithRedirect("apple.com")'>
        <apple-svg class='h-24'/>
        <div class='ml-16'>{{ $t('common.apple-id') }}</div>
      </button-element>
    </div>
    <snackbar-element
      ref='errorSnackbar'
      :duration='5000'>
      <div class='flex p-16 text-red'>
        <i class='icon mr-16'>error</i>
        <div v-if='error === "unknown"'>{{ $t('pages.login.2c6e6cd4') }}</div>
      </div>
    </snackbar-element>
    <dialog-element ref='errorDialog'>
      <div class='py-48 px-24 text-center'>
        <i class='icon text-5xl text-orange'>warning</i>
        <div class='mt-16'>{{ $t('pages.login.522e37ae') }}</div>
      </div>
      <template #actions>
        <button-element class='is-block py-16 px-24 rounded-none rounded-b'
          @click.native='signInWithRedirect("facebook.com")'
          v-if='signInMethod === "facebook.com"'>
          {{ $t('common.facebook') }}
        </button-element>
        <button-element class='is-block py-16 px-24 rounded-none rounded-b'
          @click.native='signInWithRedirect("google.com")'
          v-if='signInMethod === "google.com"'>
          {{ $t('common.google') }}
        </button-element>
        <button-element class='is-block py-16 px-24 rounded-none rounded-b'
          @click.native='signInWithRedirect("apple.com")'
          v-if='signInMethod === "apple.com"'>
          {{ $t('common.apple') }}
        </button-element>
        <button-element class='is-block py-16 px-24 rounded-none rounded-b'
          @click.native='$refs.errorDialog.close()'
          v-if='signInMethod === "password"'>
          {{ $t('common.email-and-password') }}
        </button-element>
      </template>
    </dialog-element>
  </div>
</template>

<script>
  import Apple from '@/assets/graphics/apple.svg'
  import Facebook from '@/assets/graphics/facebook.svg'
  import Google from '@/assets/graphics/google.svg'

  export default {
    components: {
      'apple-svg': Apple,
      'facebook-svg': Facebook,
      'google-svg': Google
    },

    data: () => ({
      error: '',
      signInMethod: ''
    }),

    mounted () {
      this.handleRedirectResult()
    },

    methods: {
      async signInWithRedirect (providerId) {
        try {
          await this.$store.dispatch('auth/signInWithRedirect', { providerId })
        } catch {
          this.error = 'unknown'
          this.$refs.errorSnackbar.show()
        }
      },

      async handleRedirectResult () {
        try {
          await this.$store.dispatch('auth/getRedirectResult')
        } catch (error) {
          if (error.code === 'auth/account-exists-with-different-credential') {
            let email = error.email
            let [method] = await this.$store
              .dispatch('auth/fetchSignInMethod', { email })

            let pendingCredential = JSON.stringify(error.credential.toJSON())
            sessionStorage.setItem('pending-credential', pendingCredential)

            this.signInMethod = method
            this.$refs.errorDialog.open()
            return
          }

          this.error = 'unknown'
          this.$refs.errorSnackbar.show()
        }
      }
    }
  }
</script>
