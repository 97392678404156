<template>
  <div>
    <div class='mt-48'>
      <input-element class='shadow-sm'
        type='email'
        v-model='inputs.email'
        :placeholder='$t("common.email")'/>
      <input-element class='mt-16 shadow-sm'
        type='password'
        v-model='inputs.password'
        :placeholder='$t("common.password")'/>
      <input-element class='mt-16 shadow-sm'
        type='password'
        v-model='inputs.retypePassword'
        :placeholder='$t("common.retype-password")'/>
    </div>
    <button-element class='is-block p-16 mt-24'
      :disabled='loading'
      @click.native='createUser'>
      <spinner-element class='mr-16 -ml-16 text-gray-500'
        v-if='loading'/>
      <div>{{ $t('common.sign-up') }}</div>
    </button-element>
    <snackbar-element
      ref='errorSnackbar'
      :duration='5000'>
      <div class='flex p-16 text-red'>
        <i class='icon mr-16'>error</i>
        <div v-if='error === "password-not-match"'>{{ $t('pages.login.d6fc0475') }}</div>
        <div v-if='error === "invalid-email"'>{{ $t('pages.login.c8b7f36a') }}</div>
        <div v-if='error === "weak-password"'>{{ $t('pages.login.45baebd0') }}</div>
        <div v-if='error === "email-already-in-use"'>{{ $t('pages.login.907680f3') }}</div>
        <div v-if='error === "unknown"'>{{ $t('pages.login.2c6e6cd4') }}</div>
      </div>
    </snackbar-element>
  </div>
</template>

<script>
  export default {
    data: () => ({
      inputs: {
        email: '',
        password: '',
        retypePassword: ''
      },
      error: '',
      loading: false
    }),

    methods: {
      async createUser () {
        let { email, password, retypePassword } = this.inputs

        if (password !== retypePassword) {
          this.error = 'password-not-match'
          this.$refs.errorSnackbar.show()
          return
        }

        try {
          this.loading = true

          await this.$store
            .dispatch('auth/createUser', { email, password })

          this.$parent.redirect()
        } catch (error) {
          if (error.code === 'auth/invalid-email') {
            this.error = 'invalid-email'
            this.$refs.errorSnackbar.show()
            return
          }

          if (error.code === 'auth/weak-password') {
            this.error = 'weak-password'
            this.$refs.errorSnackbar.show()
            return
          }

          if (error.code === 'auth/email-already-in-use') {
            this.error = 'email-already-in-use'
            this.$refs.errorSnackbar.show()
            return
          }

          this.error = 'unknown'
          this.$refs.errorSnackbar.show()
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>
