<template>
  <div>
    <div class='mt-48'>
      <input-element class='shadow-sm'
        type='email'
        v-model='inputs.email'
        :placeholder='$t("common.email")'/>
      <input-element class='mt-16 shadow-sm'
        type='password'
        v-model='inputs.password'
        :placeholder='$t("common.password")'/>
    </div>
    <div class='flex justify-end mt-16'>
      <div class='text-sm italic text-gray-500 hover:text-primary cursor-pointer'
        @click='$parent.tab = "password"'>
        {{ $t('pages.login.d7019437') }}
      </div>
    </div>
    <button-element class='is-block p-16 mt-24'
      :disabled='loading'
      @click.native='signInWithEmail'>
      <spinner-element class='mr-16 -ml-16 text-gray-500'
        v-if='loading'/>
      <div>{{ $t('common.log-in') }}</div>
    </button-element>
    <snackbar-element
      ref='errorSnackbar'
      :duration='5000'>
      <div class='flex p-16 text-red'>
        <i class='icon mr-16'>error</i>
        <div v-if='error === "wrong-password"'>{{ $t('pages.login.5eb9ce01') }}</div>
        <div v-if='error === "too-many-requests"'>{{ $t('pages.login.e1033413') }}</div>
        <div v-if='error === "unknown"'>{{ $t('pages.login.2c6e6cd4') }}</div>
      </div>
    </snackbar-element>
  </div>
</template>

<script>
  export default {
    data: () => ({
      inputs: {
        email: '',
        password: ''
      },
      error: '',
      loading: false
    }),

    methods: {
      async signInWithEmail () {
        try {
          this.loading = true

          let { email, password } = this.inputs
          await this.$store
            .dispatch('auth/signInWithEmail', { email, password })

          this.$parent.redirect()
        } catch (error) {
          if (
            error.code === 'auth/invalid-email' ||
            error.code === 'auth/user-not-found' ||
            error.code === 'auth/wrong-password'
          ) {
            this.error = 'wrong-password'
            this.$refs.errorSnackbar.show()
            return
          }

          if (error.code === 'auth/too-many-requests') {
            this.error = 'too-many-requests'
            this.$refs.errorSnackbar.show()
            return
          }

          this.error = 'unknown'
          this.$refs.errorSnackbar.show()
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>
