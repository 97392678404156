<template>
  <div>
    <div class='mt-48'>
      <input-element class='shadow-sm'
        type='email'
        v-model='inputs.email'
        :placeholder='$t("common.email")'/>
    </div>
    <button-element class='is-block p-16 mt-24'
      :disabled='loading'
      @click.native='sendEmail'>
      <spinner-element class='mr-16 -ml-16 text-gray-500'
        v-if='loading'/>
      <div>{{ $t('common.send-email') }}</div>
    </button-element>
    <snackbar-element
      ref='successSnackbar'
      :duration='3000'>
      <div class='flex p-16 text-green'>
        <i class='icon mr-16'>check_circle</i>
        <div v-if='success === "email-sent"'>{{ $t('pages.login.8744a52f') }}</div>
      </div>
    </snackbar-element>
    <snackbar-element
      ref='errorSnackbar'
      :duration='5000'>
      <div class='flex p-16 text-red'>
        <i class='icon mr-16'>error</i>
        <div v-if='error === "invalid-email"'>{{ $t('pages.login.c8b7f36a') }}</div>
        <div v-if='error === "user-not-found"'>{{ $t('pages.login.6b16574c') }}</div>
        <div v-if='error === "unknown"'>{{ $t('pages.login.2c6e6cd4') }}</div>
      </div>
    </snackbar-element>
  </div>
</template>

<script>
  export default {
    data: () => ({
      inputs: {
        email: ''
      },
      success: '',
      error: '',
      loading: false
    }),

    methods: {
      async sendEmail () {
        try {
          this.loading = true

          let email = this.inputs.email
          await this.$store
            .dispatch('auth/sendPasswordResetEmail', { email })

          this.success = 'email-sent'
          this.$refs.successSnackbar.show()
        } catch (error) {
          if (error.code === 'auth/invalid-email') {
            this.error = 'invalid-email'
            this.$refs.errorSnackbar.show()
            return
          }

          if (error.code === 'auth/user-not-found') {
            this.error = 'user-not-found'
            this.$refs.errorSnackbar.show()
            return
          }

          this.error = 'unknown'
          this.$refs.errorSnackbar.show()
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>
